<template>
    <ConfirmDialog group="dialog" />
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-fluid">
                <div class="p-col-12" id="double-text">
                    <h4><strong>{{ $t('Bulletins.ViewBulletin.Heading') }}</strong></h4>
                    <router-link :to="'/BulletinManagementEdit/' + $route.params.id">
                        <Button :label="$t('Bulletins.ViewBulletin.Edit')"
                            class="p-button-outlined p-mr-2 p-mb-2 p-button-sm" id="Save" icon="pi pi-pencil"
                            iconPos="left"></Button>
                    </router-link>
                </div>

                <div class="p-field p-grid">
                    <label for="name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Bulletins.ViewBulletin.Title')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ title }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Bulletins.ViewBulletin.Description')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ description }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Bulletins.ViewBulletin.Category')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ category_name }}</p>
                    </div>
                </div>



                <div class="p-field p-grid">
                    <label for="description_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Bulletins.ViewBulletin.Country')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ country_name }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Bulletins.ViewBulletin.Status')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ expose === '0' ? 'No' : 'Yes' }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Bulletins.ViewBulletin.Images')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <img :src="image_path" alt="이미지를 사용할 수 없음" class="product-image" width="137" height="85" />
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('Bulletins.ViewBulletin.PostedDate&Time')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ dateformat(createdDate) }}</p>
                    </div>
                </div>
                <div class="p-col-12" v-if="comments.length === 0">
                    <div class="card p-fluid">
                        <h4><strong>{{ $t('Bulletins.ViewBulletin.NoComments') }}</strong></h4>
                    </div>
                </div>
                <div class="p-col-12" v-else>
                    <div style="height: 165px; overflow-y:scroll">
                        <div class="card p-fluid" v-for="(item, index) in comments" :key="index">
                            <h4><strong>{{ $t('Bulletins.ViewBulletin.Comments') }}</strong></h4>
                            <div class="p-field p-grid">
                                <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"><img
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6UlEQVR4nNWXS2wOURTHf62WUmXhlZRWaUNXNgSxQ1Ui7UIEkUhZecRjVxUs8Gm86tFUSIiVjSYSj51HRAWfUiQk4pUgEWFDqkL0K5/c5D/ydTr3zszXSvgnk8zcc885d8458z9n4D9FAVABzAJmAOOB/L/ttAzYBSSBbiDtu3qAW0CDDjdgmAi0Aj+BZ8A+YD5QCRTqMvdVwH7guQ5zGijpr/O1wHfgPjAvhp45YId0V2fjOBc4qVBvAHIs+wYBeRaZ0dkEpIBD2hsZx4FPwFyLfDHQrrT8Ah4Ayx3R+Aw0RXW+Rm9uc75Djs0hFwE1QIvyvtdxCGOzLsx5OfBVYQ/CbL3xsgBZrWS2g28GvoUV5hkVnC3nprKvOvTPA2cddWVSdcqmPFlhdFX7bSDhkNcDjx3yKhWliXQfbNd37sIV4IRDbnjgToiNF8CWIEGHSMYFo/gOGBkgGwa8AnaH2DgAtPkX84EfCpELI4A3wGVgXMb6KOAC8F73LixQGnrxR6X4fArhmAo8BbqA68A14IveflpE/bQa2B/M1GIR0WAithQ4DBwBVgBDIuoWydf0zMU5MQ4wHFgCNAPndDVrrTDbA1Ro0YTHhsHAVtGqCflF4BhwFLgEdErWoL02eOku9hvvcRThGOAm8AFYZ3FQIBr/qL2jLbaqRct9mlhSn4gfQ4F7YrFehWNBsfa2S9ePg8CNIMWdIgk/TH5fA2OJDhOxtypQP17aiKhMaTCdy8Mk8YPpeHFRK11jw0/FhvYD0Qo8VOPwWq+L28PwBNime2PzkQYd5/zXBWzUsymmPf04QGNGvr12PCFMab3CVC3aDR0iHFglGwtlc2VUxRZ9191Z5t9DjWx0xhnJ0ADZJMJozKiJOMiVblq2Yg2lHuqUt6RGLduklIkcDTV3pRs57DaUaIxKqQsmlNPSjB+TUq0ltCelag8tuDgoF4G0yYH/1yyliq93fecDhTxRrvkxNV3N3Nt+UP5t/AZoVK5P6jp21AAAAABJRU5ErkJggg==">{{
                                            item.name === null ? item.email : item.name
                                        }}:</label>
                                <div class="p-col-12 p-md-10">
                                    <p>{{ FormatDate(item.created_at) }}</p>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-10">
                                <p>{{ item.comment }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="p-col-12">-->
                <div class="card p-fluid">
                    <h4><strong>{{ $t('Bulletins.ViewBulletin.Comments') }}</strong></h4>
                    <div class="p-field p-grid">

                        <div class="p-col-12 p-md-12">
                            <textarea style="width: 100%; margin-left: 0px; margin-top: 14px"
                                @keydown.space="preventLeadingSpace" :autoResize="true" rows="5" cols="30"
                                v-model="Bulletcomments" :placeholder="$t('Bulletins.ViewBulletin.CommentsPlace')"
                                maxlength="500"></textarea>
                        </div>
                        <div class="p-d-flex">
                            <Button :label="$t('Bulletins.ViewBulletin.PostComments')"
                                class="p-button-outlined p-mr-2 p-mb-2 p-button-sm" id="Save" icon="pi pi-check"
                                iconPos="left" @click="PostComments()"></Button>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
                <div class="p-d-flex p-jc-end p-ai-center">
                    <div>
                        <Button :label="$t('Bulletins.ViewBulletin.Back')" icon="pi pi-replay" iconPos="left"
                            class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2"
                            @click="$router.go(-1)"></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
// import { useRoute } from 'vue-router';
import BulletinManagementService from '../../../service/API/SuperAdmin/BulletinManagementService.js';
import axios from 'axios';
import moment from 'moment';
export default {
    data() {
        return {
            // dropdownValues: [{ name: 'nft',code:'NFT' }, { name: 'card_news',code:'Card News' }, { name: 'media_press',code:'Media press' }, { name: 'de_fi_services',code:'De-Fi Services' }],
            dropdownstatusValues: [
                { name: 'Active', code: 'active' },
                { name: 'Inactive', code: 'deleted' },
            ],
            serial: 0,
            dropdownstatusValue: null,

            isModalVisible: false,
            dropdownCountryValues: '',
            dropdownCountryValue: null,
            dropdownBoardCategoryValues: '',
            dropdownBoardCategoryValue: null,
            products: null,
            loading1: true,
            deletedID: null,
            sl_no: '',
            title: '',
            country_id: '',
            category_id: '',
            post_date: '',
            status: '',
            total: '',
            description: '',
            id: '',
            expose: '',
            image_path: '',
            createdDate: '',
            comment: '',
            Bulletcomments: '',
            country_name: '',
            category_name: '',
            comments: ''
        };
    },
    created() {
        this.bulletinManagementService = new BulletinManagementService();
    },
    mounted() {
        this.UserComments(this.$route.params.id)
        this.bulletinManagementService.viewBulletinManagement(this.$route.params.id).then((res) => {
            this.title = res.data.data[0].title;
            this.description = res.data.data[0].description;
            this.category_id = res.data.data[0].category_id;
            this.country_id = res.data.data[0].country_id;
            this.status = res.data.data[0].status;
            this.image_path = res.data.data[0].image_path;
            this.createdDate = res.data.data[0].createdDate;
            this.expose = res.data.data[0].expose;
            this.category_name = res.data.data[0].category_name;
            this.country_name = res.data.data[0].country_name;
            this.comments = res.data.data[0].comments;
            // this.comment = res.data.data[0].comments[0].comment;
            // this.name = res.data.data[0].comments[0].name;
            // this.created_at = res.data.data[0].comments[0].created_at;
            // this.mydata.type = res.data.data[0].type;
            console.log(res)
        });
    },
    methods: {
        dateformat(value) {
            if (value) {
                // return moment(String(value)).format('YYYY/MM/DD   h:mm:ss');
                return moment(String(value)).locale('ko').format('ll - LTS');
            }
        },
        FormatDate(value) {
            if (value) {
                // return moment(String(value)).format('YYYY/MM/DD  h:mm:ss');
                return moment(String(value)).locale('ko').format('ll - LTS');
            }
        },
        UserComments(data) {
            this.loading1 = true;
            this.bulletinManagementService
                .viewBulletinManagement(data)
                .then((r) => {
                    this.loading1 = false;
                    this.comments = r.data.data[0].comments;
                    // this.myCallback(1);
                    console.log('details', this.comments);
                })
                .catch((err) => {
                    this.loading1 = false;
                    console.log(err);
                });
        },
        PostComments() {
            // let vcheckData = {
            //     note: this.note,
            //     // type: this.dropdownValueType == null ? '' : 'something',
            // };
            // const { isInvalid, error } = validateJobSeekerNote(vcheckData);
            // if (isInvalid) {
            //     this.error = error;
            //     console.log(error);
            // } else {
            //     console.log(this.formData);
            return axios
                .post('/asnhist-dev/api/v1/admin/board/add-comments', { bulletin_board_id: this.$route.params.id, comments: this.Bulletcomments })
                .then(() => {

                    this.$router.push({ name: 'BulletinManagementView' });
                    setTimeout(() => {
                        this.$toast.add({ severity: 'success', summary: '업데이트 완료', detail: '메모가 저장되었습니다.', life: 3000 });
                        window.location.reload();
                    }, 500);
                });
            // }
        },
        preventLeadingSpace(e) {
            // only prevent the keypress if the value is blank
            if (!e.target.value) e.preventDefault();
            // otherwise, if the leading character is a space, remove all leading white-space
            else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, "");
        },
    }


};

</script>
<style scoped>
.p-fluid .p-button {
    width: auto;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
    color: white;
}

.product-image {
    padding-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 150px;
    height: 100px;

}

button#Save {
    margin-left: 13px;
    margin-top: 15px;
}

div#double-text {
    display: flex;
    justify-content: space-between;
}
</style>