import axios from 'axios';
export default class BulletinManagementService {

    async getBulletinManagementList(data) {
        return await axios.post('asnhist-dev/api/v1/admin/board', data);
    }

    async getCountrydropdown() {
        return await axios
            .get(`asnhist-dev/api/v1/admin/country`, {
                
            })
            .then((res) => res);
    }


    // async getBoardCategorydropdown() {
    //     return await axios
    //         .get(`asnhist-dev/api/v1/admin/board-category/all`, {
                
    //         })
    //         .then((res) => res);
    // }

    async getBoardCategorydropdown(country_id) {
            return await axios.post('asnhist-dev/api/v1/admin/board-category/all', { country_id: country_id , category_type : 'bulletin'})
            .then((res) => res);
        
    }

    

    async viewBulletinManagement(ids) {
        return await axios.post(`asnhist-dev/api/v1/admin/board/id`, { id: ids }).then((res) => res);
    }

    
}